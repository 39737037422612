import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import 'rxjs';
declare var $: any;

export interface IDataSortModel {
    /**
     * The value displayed in the sort control
     *
     * @memberof IDataSortModel
     */
    displayName: string;
    /**
     * The id used for the sort control item
     *
     * @memberof IDataSortModel
     */
    id: string;
    /**
     * The direction of sort. This is used by lodash and allowed values are asc and desc
     *
     * @memberof IDataSortModel
     */
    order: string; // asc or desc
    /**
     * The lodash sort function used to sort. If simple sorting can just be column name
     *
     * @memberof IDataSortModel
     */
    sortFunction: any;
    /**
     * Is the currently selected for sorting
     *
     * @memberof IDataSortModel
     */
    isSortSelected: boolean;
}

@Component({
  selector: 'ngxcommon-dataSorting',
  template: `
<div class="floated right inlineAction noWrap">
  <span>
      Sort by 
          <div class="ui inline right dropdown" id="{{sortControl}}">
              <i class="dropdown icon"></i>
              <div *ngIf="selectedField" id="selectedField" class="text">{{selectedField.displayName}}</div>
              <div class="left menu" id="sortDropdownItems">
              <div class="item" id="{{field.id + 'SortField'}}"
              *ngFor="let field of data"
              [ngClass]="{active: field.isSortSelected}"  
              (click)="sortFieldChanged(field)">{{field.displayName}}
              </div>
              </div>
          </div>
      <div id="sortDirectionButton" class="ui basic small button" (click)="sortDirectionChanged()">
          <i class="sort content icon" [class.descending]="selectedField.order === 'desc'" [class.ascending]="selectedField.order === 'asc'"></i>
      </div>
  </span>
</div>
  `,
  styles: [`.noWrap{white-space:nowrap}`]
})

export class DataSortingComponent implements AfterViewInit {
  @Input() public data: IDataSortModel[];
  @Input() public sortControl = 'sortDropdown';
  @Output() public sortSettingsChanged: EventEmitter<IDataSortModel> = new EventEmitter();

  get selectedField(): IDataSortModel {
    let result = null;
    this.data.forEach((col) => {
      if (col.isSortSelected) {
        result = col;
      }
    });
    return result;
  }

  /* istanbul ignore next: no easy way to test ngAfterViewInit */
  public ngAfterViewInit() {
    if (typeof $ !== 'undefined') {
        $(`#${this.sortControl}`).dropdown();
    }
}

  public sortFieldChanged(dataField: IDataSortModel) {
    if (dataField.id !== this.selectedField.id) {
      const newSortDirection = this.selectedField.order;
      this.data.forEach((field) => {
        field.order = 'asc';
        field.isSortSelected = false;
      });

      dataField.isSortSelected = true;
      dataField.order = newSortDirection;

      this.sortSettingsChanged.emit(dataField);
    }
  }

  public sortDirectionChanged() {
    let newSortDirection = 'asc';
    if (this.selectedField.order === 'asc') {
      newSortDirection = 'desc';
    }

    this.data.forEach((field) => {
      if (field.isSortSelected) {
        field.order = newSortDirection;
      }
    });

    this.sortSettingsChanged.emit(this.selectedField);
  }
}
