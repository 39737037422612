import {from as observableFrom} from 'rxjs';

import { debounceTime, bufferCount, distinctUntilChanged } from 'rxjs/operators';
import { TableModel } from '../../models/table-model';
import { FormControl } from '@angular/forms';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TableSearchModel } from '../../models/table-search';

@Component({
  selector: 'ng2common-tableSearch',
  template: `<div class="ui icon input">
  <input [(maxLength)]="maxSearchLength" [formControl]="searchBox" id="searchInput" type="text" [(ngModel)]="tableSearching.searchString">
  <i *ngIf="tableSearching.searchString === ''" class="search icon"></i>
  <span *ngIf="tableSearching.searchString != ''" (click)="onClearFilter();"
  style="position: absolute; top: 45%; right: 10px; cursor: pointer;" id="resetSearchIcon">
      <i class="red remove icon"></i>
  </span>
</div>
<label id="searchLabel">{{searchLabel}}</label>`,
  styles: [``]
})
export class TableSearchComponent {
  public static searched = 'searched';
  public static cleared = 'cleared';
  public static noresults = 'noresults';

  @Input() public tableData: TableModel;
  @Input() public maxSearchLength: number | null = undefined;
  @Input() public showSuperscript = true;
  @Input() public searchText: string;
  @Input() public isOldMethod: boolean;
  @Input() public chunkSize = 200;
  @Input() public chunkThreshold = 400;
  public searchBox: FormControl = new FormControl();
  @Output() public tableSearched: EventEmitter<any> = new EventEmitter();

  @Input() public tableSearching: TableSearchModel = {
      columnNames: ['name'],
      searchString: '',
      customFilterMethod: null
  };

  /**
   * SearchLabel generates the superscript text to render over the input
   *
   * If showSuperscript is false, we return an empty string. If it is true,
   * we show Search for `yourText`
   */
  get searchLabel(): string {
      return this.showSuperscript ?
          `Search for ${this.searchText}` :
          ``;
  }

  /**
   * Determines if a given row meets the criteria in the search columns and search text.
   * *
   * param {*} row : An object containing the properties being searched for.
   * param {*} searchColumns: a colection of columns\properties to search
   * param {string} searchText: The text used to search
   * returns {boolean}
   * memberof TableSearchComponent
   */
  public static shouldFilterIn(row: any, searchColumns: any, searchText: string): boolean {
      let result = false;
      for (let i = 0; i < searchColumns.length; i++) {
          const searchCol = searchColumns[i];
          if (row[searchCol].toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
              result = true;
          }
      }
      return result;
  }

  public constructor() {
      this.searchBox.valueChanges.pipe(
          debounceTime(200),
          distinctUntilChanged(), )
          .subscribe((event) => this.tableData.rows = this.isOldMethod ? this.onChangeFilterOld(event) : this.onChangeFilter(event));
  }

  public onClearFilter() {
      this.tableSearching.searchString = '';
      const filteredRows = this.isOldMethod ? this.tableData.filteredRows : this.tableData.allRows.filter((row) => row.isFiltered);
      if (filteredRows.length <= this.chunkThreshold) {
          this.tableData.rows = filteredRows;
          this.tableSearched.emit(TableSearchComponent.cleared);
          return;
      }
      observableFrom(filteredRows).pipe(
      bufferCount(this.chunkSize))
      .subscribe((chunk) => {
          setTimeout(() => {
              this.tableData.rows.push(...chunk);
              this.tableSearched.emit();
          }, 25);
      });
  }

  public onChangeFilter(event: any): Array<any> {
      let result = new Array<any>();
      if (event === '') {
          result = this.tableData.allRows.filter((row) => row.isFiltered);
      } else {
          for (let j = 0; j < this.tableData.allRows.length; j++) {
              // If the user specifies a custom filter method, then break out of default behavior and let them control it
              if ( this.tableSearching.customFilterMethod ) {
                  if ( this.tableSearching.customFilterMethod(this.tableData.allRows[j], this.tableSearching.searchString) ) {
                      result.push(this.tableData.allRows[j]);
                  }
                  // Otherwise, iterate over the columns specified in columnNames and check if any of them in the row in question contains at least the search string
              } else {
                  let rowAdded = false;
                  for (let i = 0; i < this.tableSearching.columnNames.length; i++) {
                      const searchCol = this.tableSearching.columnNames[i];
                      const row = this.tableData.allRows[j];
                      if (row.isFiltered && !rowAdded && row[searchCol] &&
                          row[searchCol].toLowerCase().indexOf(event.toLowerCase()) >= 0) {
                          result.push(row);
                          rowAdded = true;
                      }
                  }
              }
          }
      }

      const search = result.length === 0 && this.tableData.allRows.length !== 0
      ? TableSearchComponent.noresults : TableSearchComponent.searched;
      this.tableSearched.emit(search);
      return result;
  }

  public onChangeFilterOld(event: any): Array<any> {
      let result = new Array<any>();
      if (event === '') {
          result = this.tableData.filteredRows;
      } else {
          for (let j = 0; j < this.tableData.filteredRows.length; j++) {
              let rowAdded = false;
              for (let i = 0; i < this.tableSearching.columnNames.length; i++) {
                  const searchCol = this.tableSearching.columnNames[i];
                  const row = this.tableData.filteredRows[j];
                  if (!rowAdded && row[searchCol] &&
                      row[searchCol].toLowerCase().indexOf(event.toLowerCase()) >= 0) {
                      result.push(row);
                      rowAdded = true;
                  }
              }
          }
      }

      const search = result.length === 0 && this.tableData.filteredRows.length !== 0
      ? TableSearchComponent.noresults : TableSearchComponent.searched;
      this.tableSearched.emit(search);
      return result;
  }

}
