import { IColumnModel } from '../models/table-model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'reportDataValue',
})
export class ReportDataValuePipe implements PipeTransform {

    public transform(value: any, column?: IColumnModel): string {
        return column.parseValue(value);
    }
}
