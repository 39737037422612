import { Component, OnInit } from '@angular/core';
import { MenuItemService } from '../../services/menu-item.service';
import { ISelectedItem } from '../../models/dynamic-menu-model';
import { Observable } from 'rxjs';
import { UrlParserService } from '../../services/url-parser.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mainmenu',
  template: `<nav [ngClass]="{'level1-collapsed': collapsed, 'level2-visible': collapsed, 'level1-expanded': !collapsed}">
  <div class="ui sticky menu fixed top">
      <div class="vertical menu" [ngClass]="{collapsed: collapsed}">
          <ul class="block">
              <li class="item" *ngFor="let item of menuItems" [ngClass]="{active: item.active}">
                  <a *ngIf="item.localRoute" [routerLink]="item.localRoute">
                      <img class="ui spaced image" src="{{item.iconUrl}}" alt="{{item.displayText}}" />
                      <span>{{item.displayText}}</span>
                  </a>
                  <a *ngIf="!item.localRoute" [href]="item.linkUrl">
                      <img class="ui spaced image" src="{{item.iconUrl}}" alt="{{item.displayText}}" />
                      <span>{{item.displayText}}</span>
                  </a>
              </li>
          </ul>
      </div>
      <ng-template ngFor let-topitem [ngForOf]="menuItems">
          <div class="vertical secondaryMenu" *ngIf="topitem.subItems" [hidden]="!topitem.active">
              <ng-template ngFor let-seconditem [ngForOf]="topitem.subItems">
                  <span class="label">{{seconditem.displayText}}</span>
                  <ul *ngIf="seconditem.subItems">
                      <li class="item" *ngFor="let thirditem of seconditem.subItems" [ngClass]="{active: thirditem.active}">
                          <a *ngIf="thirditem.localRoute" [routerLink]="thirditem.localRoute">{{thirditem.displayText}}</a>
                          <a *ngIf="!thirditem.localRoute" [href]="thirditem.linkUrl">{{thirditem.displayText}}</a>
                      </li>
                  </ul>
              </ng-template>
              <div>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
              </div>
              <ng-container *ngIf="selectedItem | async; let selectedItem">
                  <span class="label">{{selectedItem.displayText}}</span>
                  <ul *ngIf="selectedItem.subItems.length > 0">
                      <li class="item" *ngFor="let subitem of selectedItem.subItems" [ngClass]="{active: subitem.active}">
                          <a *ngIf="subitem.localRoute" [routerLink]="subitem.localRoute">{{subitem.displayText}}</a>
                          <a *ngIf="!subitem.localRoute" [href]="subitem.linkUrl">{{subitem.displayText}}</a>
                      </li>
                  </ul>
              </ng-container>
          </div>
      </ng-template>
  </div>
</nav>
`,
  styles: [``],
  providers: [UrlParserService]
})
export class MainMenuComponent implements OnInit {
  menuItemKey = '';
  menuItems: any[];
  collapsed = false;
  selectedItem: Observable<ISelectedItem>;

  constructor(private menuItemService: MenuItemService, private router: Router) {
  }

  getMenuItems() {
    this.menuItemService.getMenuItems().then(x => {
      this.menuItems = x;
      this.setState(this.menuItemKey);
      this.selectedItem = this.menuItemService.selectedItem$;
    });
  }

  match(key1: string, key2: string): number {
    if (key1 == null || key2 == null) {
      return 0;
    }
    const expandedkey1 = key1.split('.');
    const expandedkey2 = key2.split('.');

    if (expandedkey1.length > expandedkey2.length) {
      return 0;
    }

    let strength = 0;
    for (let i = 0; i < expandedkey1.length; i++) {
      if (expandedkey1[i] !== expandedkey2[i]) {
        break;
      }
      strength++;
    }
    return strength;
  }

  setState(routeKey) {
    let bestmatch = 0;
    let match = null;

    if (this.menuItems == null) {
      return;
    }
    const recurseTree = (items, parent) => {
      for (const item of items) {
        item.parent = parent;
        item.active = false;

        if (item.subItems) {
          recurseTree(item.subItems, item);
        }
        const strength = this.match(item.menuItemKey, routeKey);
        if (strength > bestmatch) {
          match = item;
          bestmatch = strength;
        }
      }
    };

    recurseTree(this.menuItems, null);

    let active = match;
    while (active) {
      active.active = true;
      active = active.parent;
    }

    this.collapsed = false;
    for (const x of this.menuItems) {
      if (x.active && x.subItems && x.subItems.length > 0) {
        this.collapsed = true;
      }
    }
  }

  ngOnInit() {
    this.getMenuItems();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        let route: ActivatedRoute = this.router.routerState.root.firstChild;
        let key = route.routeConfig.data.menuKey;
        while (route.firstChild) {
          route = route.firstChild;
          key = route.routeConfig.data.menuKey || key;
        }

        this.setState(key);
        this.menuItemKey = key;
      }
    });

  }

}
