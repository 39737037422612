import { ColumnModel, TableModel } from '../../models/table-model';
import { Component, Input, AfterViewInit } from '@angular/core';
import moment from 'moment-with-locales-es6';
declare var $: any;

@Component({
  selector: 'ng2common-sort-control',
  template: `<div class="floated right inlineAction">
  <span>
      Sort by 
          <div class="ui inline right dropdown" [ngClass]="{simple: isSimple}" id="{{sortControl}}">
              <i class="dropdown icon"></i>
              <div *ngIf="selectedColumn" class="text">{{selectedColumn.detailName}}</div>
              <div class="left menu">
                  <div    class="item" 
                          *ngFor="let column of tableData.columns"
                          [ngClass]="{active: column.isSortSelected}"  
                          (click)="sortColumnChanged(column)">{{column.detailName}}
                  </div>
              </div>
          </div>
      <div class="ui basic small button" (click)="sortDirectionChanged()">
          <i class="sort content icon" [ngClass]="sortDirection" ></i>
      </div>
  </span>
</div>
`,
  styles: [``]
})
export class SortControlComponent implements AfterViewInit {
  @Input() public tableData: TableModel;
  @Input() public sortControl = 'sortDropdown';
  @Input() public isSimple = true;

  public static ChangeTableSort(tableData: TableModel): any {
      if (tableData.rows) {
          tableData.columns.forEach(col => {
              if (col.isSortSelected) {
                  const col2 = tableData.secondarySortColumn ? tableData.secondarySortColumn : col.sortDataName;
                  const sort2 = tableData.secondarySortDirection
                          ? tableData.secondarySortDirection : col.sortDirection;

                  tableData.rows.sort((previous: any, current: any) => {
                      return this.dataSort(previous[col.sortDataName], current[col.sortDataName],
                              previous[col2], current[col2], col.sortDirection, sort2, col.sortDataType,
                              tableData.secondarySortType);
                  });
                  /* istanbul ignore next: no easy way to test filteredRows */
                  if (tableData.filteredRows) {
                      tableData.filteredRows.sort((previous: any, current: any) => {
                          return this.dataSort(previous[col.sortDataName], current[col.sortDataName],
                                  previous[col2], current[col2], col.sortDirection, sort2, col.sortDataType,
                                  tableData.secondarySortType);
                      });
                  }
                  tableData.allRows.sort((previous: any, current: any) => {
                      return this.dataSort(previous[col.sortDataName], current[col.sortDataName],
                               previous[col2], current[col2], col.sortDirection, sort2, col.sortDataType,
                               tableData.secondarySortType);
                  });
              }
          });
      }
  }

  public static dataSort( previous: any, current: any, previous2: any, current2: any,
                          sortDirection: string, sortDirection2: string, sortType: string, sortType2: string) {
      let previousVal;
      let currentVal;
      let previousVal2;
      let currentVal2;

      switch (sortType) {
          case 'numeric':
              previousVal = this.numericConvertValue(previous);
              currentVal = this.numericConvertValue(current);
              break;
          case 'date':
              previousVal = this.momentConvertValue(previous);
              currentVal = this.momentConvertValue(current);
              break;
          default:
              previousVal = this.defaultConvertValue(previous);
              currentVal = this.defaultConvertValue(current);
      }

      switch (sortType2) {
          case 'numeric':
              previousVal2 = this.numericConvertValue(previous2);
              currentVal2 = this.numericConvertValue(current2);
              break;
          case 'date':
              previousVal2 = this.momentConvertValue(previous2);
              currentVal2 = this.momentConvertValue(current2);
              break;
          default:
              previousVal2 = this.defaultConvertValue(previous2);
              currentVal2 = this.defaultConvertValue(current2);
      }

      return this.sort(previousVal, currentVal, previousVal2, currentVal2, sortDirection, sortDirection2);
  }

  private static sort(previous, current, previous2, current2, sortDirection, sortDirection2) {
      if (previous > current) {
          return sortDirection === 'descending' ? -1 : 1;
      } else if (previous < current) {
          return sortDirection === 'ascending' ? -1 : 1;
      } else if (previous2 > current2) {
          return sortDirection2 === 'descending' ? -1 : 1;
      } else if (previous2 < current2) {
          return sortDirection2 === 'ascending' ? -1 : 1;
      }
      return 0;
  }

  private static defaultConvertValue(value) {
      if (value === undefined) {
          return '';
      }

      if (typeof value === 'string') {
          return value.toLowerCase();
      }

      return value;
  }

  private static numericConvertValue(value): number {
      return  value !== undefined ? parseFloat(value) : -1;
  }

  private static momentConvertValue(value: moment.Moment): string {
      return  value !== undefined ? value.toISOString() : '1900-01-01';
  }

  /* istanbul ignore next: no easy way to test ngAfterViewInit */
  public ngAfterViewInit() {
    if (typeof $ !== 'undefined' && !this.isSimple) {
        $(`#${this.sortControl}`).dropdown();
    }
  }

  get sortDirection(): string {
      let result = '';
      if (this.selectedColumn) {
          result =  this.selectedColumn.sortDirection;
      }

      return result;
  }

  get selectedColumn(): ColumnModel {
      let result = null;
      this.tableData.columns.forEach(col => {
              if (col.isSortSelected) {
                  result =  col;
              }
          });
      return result;
  }

  public sortColumnChanged(column: ColumnModel) {
      const newSortDirection = this.sortDirection;
      this.tableData.columns.forEach(col => {
              col.sortDirection = '';
              col.isSortSelected = false;
          });

      column.isSortSelected = true;
      column.sortDirection = newSortDirection;

      SortControlComponent.ChangeTableSort(this.tableData);
  }

  public sortDirectionChanged() {
      let newSortDirection = 'ascending';
      if (this.sortDirection === 'ascending') {
          newSortDirection = 'descending';
      }
      this.tableData.columns.forEach(col => {
          if (col.isSortSelected) {
                  col.sortDirection = newSortDirection;
                  SortControlComponent.ChangeTableSort(this.tableData);
              }
      });
  }
}
