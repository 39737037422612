import { Component, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import moment from 'moment-with-locales-es6';

declare var $: any;

const _now = moment().utcOffset('Z', true);

function createDate(unparsedDate?) {
  if (unparsedDate) {
    return moment(unparsedDate).utcOffset('Z', true);
  } else {
    return _now.clone();
  }
}
@Component({
  selector: 'ng2common-date-time-raw-picker',
  template: `<div class="ui icon input">
  <i class="icon calendar"></i>
  <input type="text" [attr.id]="name" class="dateRange"
  readonly="readonly" [attr.value]="dateValue" (onChange)="onChangeDate($event)">
</div>
<label>Date Range</label>`,
  styles: [``]
})
export class DateTimeRawPickerComponent implements AfterViewInit {
  @Input() public name: string;
  @Input() public set startDate(date: moment.Moment) {
      this.selectedStartDate = date;
      const picker = $(`#${this.name}`).data('daterangepicker');
      if (picker) {
          picker.setStartDate(date);
      }
  }
  @Input() public set endDate(date: moment.Moment) {
      this.selectedEndDate = date;
      const picker = $(`#${this.name}`).data('daterangepicker');
      if (picker) {
          picker.setEndDate(date);
      }
  }
  @Input() public maxDate: moment.Moment = createDate();
  @Input() public minDate: moment.Moment = createDate().subtract(3, 'year');
  @Input() public onChangeDate: any;
  @Input() public showTime: boolean;
  @Input() public selectedRange: string = null;
  @Output() public datePicked: EventEmitter<any> = new EventEmitter();
  @Input() public ranges: {} = {
      'Today': [
          createDate().startOf('day'),
          createDate().startOf('day').add(1, 'day'),
      ],
      'Yesterday': [
          createDate().subtract(1, 'days').startOf('day'),
          createDate().subtract(1, 'days').startOf('day').add(1, 'day'),
      ],
      // tslint:disable-next-line:object-literal-sort-keys
      'This Week': [
          createDate().startOf('week'),
          createDate().startOf('week').add(1, 'week'),
      ],
      'Last Week': [
          createDate().subtract(1, 'week').startOf('week'),
          createDate().subtract(1, 'week').startOf('week').add(1, 'week'),
      ],
      'Last 7 Days': [
          createDate().startOf('day').subtract(6, 'days'),
          createDate().startOf('day').add(1, 'day'),
      ],
      'Last 30 Days': [
          createDate().startOf('day').subtract(29, 'days'),
          createDate().startOf('day').add(1, 'day'),
      ],
      'This Month': [
          createDate().startOf('month'),
          createDate().startOf('month').add(1, 'month'),
      ],
      'Last Month': [
          createDate().subtract(1, 'month').startOf('month'),
          createDate().subtract(1, 'month').startOf('month').add(1, 'month'),
      ],
      'This Quarter': [
          createDate().startOf('quarter'),
          createDate().startOf('quarter').add(1, 'quarter'),
      ],
      'Last Quarter': [
          createDate().subtract(1, 'quarter').startOf('quarter'),
          createDate().subtract(1, 'quarter').startOf('quarter').add(1, 'quarter'),
      ],
      'LYTD Calendar Yr': [
          createDate().subtract(1, 'year').startOf('year'),
          createDate().subtract(1, 'year').startOf('day').add(1, 'day'),
      ],
      'YTD Calendar Yr': [
          createDate().startOf('year'),
          createDate().startOf('day').add(1, 'day'),
      ],
  };

  private format = 'DD MMM YYYY hh:mm A';
  private selectedStartDate: moment.Moment;
  private selectedEndDate: moment.Moment;
  get dateValue(): string {
      // tslint:disable-next-line:max-line-length
      return ((this.selectedStartDate) && (this.selectedEndDate)) ? (`${this.selectedStartDate.format(this.format)} to ${this.selectedEndDate.format(this.format)}`) : '';
  }

  public ngAfterViewInit() {
      if (typeof $ === 'undefined') { return; }

      this.setDatesByRange();

      /* istanbul ignore next: no easy way to test JQuery */
      $(`#${this.name}`).daterangepicker({
          alwaysShowCalendars: true,
          format: this.format,
          startDate: this.selectedStartDate,
          // tslint:disable-next-line:object-literal-sort-keys
          endDate: this.selectedEndDate,
          maxDate: createDate().startOf('day').add(1, 'day'),
          minDate: this.minDate,
          timePicker: true,
          timePickerIncrement: 15,
          ranges: this.ranges,
      }, () => this.applyDates());
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.first.left').off('mouseenter', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.second.right').off('mouseenter', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.first.left').off('mouseleave', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.second.right').off('mouseleave', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.ranges ul li ').on('click', (e) => {
          this.selectedRange = e.currentTarget.firstChild.data;
      });

      $(`#${this.name}`).on('apply.daterangepicker', (ev, picker) => {
          this.applyDates();
       });

       /* istanbul ignore next: no easy way to test JQuery */
      $('input[name="daterangepicker_start"]').on('focusout', (event) => {
          // This version of moment, doesn't support the syntax to do utc correctly
          let time = createDate(event.target.value);
          const minDate = $('#' + this.name).data('daterangepicker').minDate;
          const maxDate = $('#' + this.name).data('daterangepicker').maxDate;

          if (!time.isValid() || minDate.isAfter(time) || maxDate.isBefore(time)) {
              time = $('#' + this.name).data('daterangepicker').oldStartDate;
          }

          $(`#${this.name}`).data('daterangepicker').setStartDate(time);
      });
      /* istanbul ignore next: no easy way to test JQuery */
      $('input[name="daterangepicker_end"]').on('focusout', (event) => {
          // This version of moment, doesn't support the syntax to do utc correctly
          let time = createDate(event.target.value);
          const minDate = $('#' + this.name).data('daterangepicker').minDate;
          const maxDate = $('#' + this.name).data('daterangepicker').maxDate;

          if (!time.isValid() || minDate.isAfter(time) || maxDate.isBefore(time)) {
              time = $('#' + this.name).data('daterangepicker').oldEndDate;
          }
          $(`#${this.name}`).data('daterangepicker').setEndDate(time);
      });

      this.applyDates();
  }

  public setDatesByRange() {
      const validDateRangeKeys = Object.keys(this.ranges);
      if (this.selectedRange && validDateRangeKeys.findIndex((dateRange) => dateRange === this.selectedRange) > -1 ) {
          this.selectedStartDate = this.ranges[this.selectedRange][0];
          this.selectedEndDate = this.ranges[this.selectedRange][1];
      }
  }

  public resetDateRange() {
      if ( !this.selectedRange ) { return; }
      const range = this.ranges[this.selectedRange];
      if ( !range ) {
          this.selectedRange = null;
          return;
      }
      if ( range[0].valueOf() !== this.selectedStartDate.valueOf() ) {
          this.selectedRange = null;
      }
      if ( range[1].valueOf() !== this.selectedEndDate.valueOf() &&
          this.selectedEndDate.endOf('day').valueOf() !== createDate(this.maxDate).endOf('day').valueOf() ) {
              this.selectedRange = null;
      }
  }

  private applyDates() {
      const picker = $(`#${this.name}`).data('daterangepicker');
      this.selectedStartDate = picker.startDate;
      this.selectedEndDate = picker.endDate;
      this.resetDateRange();
      this.datePicked.emit({
              endDate: picker.endDate,
              startDate: picker.startDate,
              // tslint:disable-next-line:object-literal-sort-keys
              selectedRange: this.selectedRange,
      });
  }
}
