import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
declare var $: any;

export interface IFilterControl {
  check?: any;
  dataType?: any;
  dropDownList?: any;
  name: string;
  type: string;
  title: string;
  sizeClass: string;
  data: Array<any>;
  searchQuery?: any;
  selectedData: any;
  selectedText;
  activate(item: any): any;
}

@Component({
    selector: 'ngxcommon-filterControls',
    template: `<div class="ui fluid popup filterOptions bottom right transition hidden fullWidth">
    <div class="ui form">
        <div class="fields">
            <div [ngClass]="control.sizeClass" class="wide field" *ngFor="let control of filterControls" [ngSwitch]="control.type">
                <ng-template [ngSwitchCase]="'DropDownThreeOptionFilter'">
                    <div class="ui">
                        <div class="ui simple dropdown selection filter">
                            {{control.selectedData.description}}
                            <i class="dropdown icon"></i>
                            <div class="menu" [attr.id]="control.name">
                                <div [attr.id]="item.value" class="item" [ngClass]="{active: control.selectedData.value === item.value}" *ngFor="let item of control.data"
                                    (click)="control.activate(item)">{{item.description}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <label>{{control.title}}</label>
                </ng-template>
                <ng-template [ngSwitchCase]="'DropDownReferenceDataFilter'">
                    <ngxcommon-dropDownReferenceDataFilter [filterControl]="control" (refFilterApply)="refFilterApply($event)"></ngxcommon-dropDownReferenceDataFilter>
                </ng-template>
                <ng-template [ngSwitchCase]="'MultiSelectFilter'">
                    <ngxcommon-multiSelectDropDownDataFilter [filterControl]="control"></ngxcommon-multiSelectDropDownDataFilter>
                </ng-template>
            </div>
        </div>
        <div class="ui divider compact light"></div>
        <button id="filterResetBtn" [ngClass]="{disabled: !canClearAll}" class="floated left ui button compact" (click)="filterReset()">Clear
            All</button>
        <button id="filterApplyBtn" class="floated right ui primary button compact filterApply" (click)="filterApply()">Apply</button>
    </div>
</div>
`,
})

export class FilterControlComponent implements OnInit {
    @Input() public filterControls: IFilterControl[];
    @Input() public buttonControl: string;
    @Output() public filtersApply: EventEmitter<any> = new EventEmitter();
    @Output() public clearFiltersApply: EventEmitter<any> = new EventEmitter();

    public canClearAll = false;

    public refFilterApply(filterControl) {
        this.checkCanClearAll();
    }

    public filterApply() {
        this.filtersApply.emit(this.filterControls);
        if (typeof $ !== 'undefined') { $('.filterBtn').click(); }
        this.checkCanClearAll();
    }

    public filterReset() {
        this.clearFilterSelection();
        this.clearFiltersApply.emit(this.filterControls);
        if (typeof $ !== 'undefined') { $('.filterBtn').click(); }
        this.checkCanClearAll();
    }

    public ngOnInit() {
        if (typeof $ !== 'undefined') {
            $(`#${this.buttonControl}`).popup({
                on    : 'click',
                popup: '.filterOptions',
                position : 'bottom right',
            });
        }
        this.checkCanClearAll();
    }

    private clearFilterSelection() {
        this.filterControls.forEach((control) => {
          if (Array.isArray(control.selectedData)) {
            control.selectedData = [];
            control.data.forEach((item) => {
              item.selected = false;
            });
          } else if (control.type === 'MultiSelectFilter') {
            control.selectedData = [];
          } else {
            const showAllItem = control.data.filter((item) => item.id === 0)[0];
            control.activate(showAllItem);
          }
        });
      }

    private checkCanClearAll() {
        this.canClearAll = false;
        this.filterControls.map((control) => {
          if (control.selectedData) {
            if ((control.selectedData.value && control.selectedData.value !== '*') || (Array.isArray(control.selectedData) && control.selectedData.length > 0) ) {
              return this.canClearAll = true;
            }
          }
        });
      }
}
