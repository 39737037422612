import { Component, Input } from '@angular/core';
import { IFilterControl } from './filter-control.component';

@Component({
    selector: 'ngxcommon-multiSelectDropDownDataFilter',
    template: `<div class="ui" >
    <div class="ui simple dropdown selection filter" [attr.id]="filterControl.name + '_filter'">
        {{filterControl.selectedText}}
        <i class="dropdown icon"></i>
        <div class="menu" style="overflow-y:scroll; max-height:400px;">
        <div class="ui icon search input">
            <i class="search icon"></i>
             <input type="text" placeholder="Search" [(ngModel)]="filterControl.searchQuery">
        </div>
        <div *ngIf="filterControl.selectedData?.length && !filterControl.searchQuery" class="group-header">Selected</div>
        <ng-container *ngIf="!filterControl.searchQuery">
            <div  *ngFor="let item of filterControl.selectedData; let i=index" [attr.id]="item.value" class="ui item checkbox" 
                (click)="filterControl.check(item, filterControl.dataType)">
            <input type="checkbox" name="item{{i}}" checked="true">
            <label>{{item.name}}</label>
            </div>
        </ng-container>
        <div *ngIf="filterControl.dropDownList?.length" class="group-header">Available</div>
        <div *ngFor="let item of filterControl.dropDownList; let j=index" [attr.id]="item.value" class="ui item checkbox" 
                (click)="filterControl.check(item, filterControl.dataType)">
            <input type="checkbox" name="item{{j}}">
            <label>{{item.name}}</label>
        </div>
        </div>
    </div>
</div>
<label>{{filterControl.title}}</label>
`,
    styles: [`.ui.simple.dropdown:hover>.menu.scroll-menu{top:calc(100% + 65px)!important}.ui.simple.dropdown:hover>.menu.search-field{border-bottom:0}.group-header{font-weight:700;padding:.5em 1em}.no-options-message{padding:1em 2em;color:#6d7176}label.control-label{min-width:180px}`],
})

export class MultiSelectDropDownFilterComponent {
    @Input() public filterControl: IFilterControl;
}
