export class DateFormatType {
  public static Date = 'date';
  public static DateTime = 'datetime';
  public static FullDate = 'fulldate';
  public static FullDateTime = 'fulldatetime';
  public static ExportDateTime = 'exportdatetime';
}
export class NumericFormatType {
  public static Decimal = 'decimal';
  public static Currency = 'currency';
}
