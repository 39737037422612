import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { CookieService } from 'ngx-cookie';
import { UrlParserService } from './url-parser.service';
import { CommonUserService } from './common-user.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  constructor(private cookieService: CookieService, private urlParserService: UrlParserService,
    private idle: Idle, private userService: CommonUserService) {}

stopIdle() {
    console.log('No longer checking for idle state.');
    this.idle.stop();
}

startIdle() {
    console.log('Idle Check enabled.');
    this.idle.watch();
}

checkIfAuthorised() {
    if (!this.cookieService.get('maxsys2accesstoken')) {
        // stop checking for inactivity if no accesstoken
        this.stopIdle();

        return false;
    }

    return true;
}

/* istanbul ignore next: built in browser logic */
getIdleExpiryDate(): number {
    return localStorage['ng2Idle.MAXsys2.0.expiry'];
}

/* istanbul ignore next: built in browser logic */
navigateToSignOut(signOutURL: string) {
    window.location.href = signOutURL;
}

setupIdle(idleTime: number, idleTimeout: number) {
    this.idle.setIdleName('MAXsys2.0');
    this.idle.setIdle(idleTime); // set the period of time that we consider being idle e.g. most likely 1 minute of no activity
    this.idle.setTimeout(idleTimeout); // set the period of time after a user is idle that we timethem out
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document

    // Subscribe to idle events.
   this.idle.onIdleStart.subscribe(() => {
       console.log('IdleStart - expiry: ' + this.getIdleExpiryDate());
    });

    this.idle.onIdleEnd.subscribe(() => {
     console.log('IdleEnd: ' + this.getIdleExpiryDate());
    });

    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
        if (countdown <= 20) {
            console.log('TimeoutWarning: ' + countdown);
        }
    });

    this.idle.onTimeout.subscribe(() => {
       console.log('Timeout');
       const signOutURL = this.userService.getSignOutUrl() + '&isTimeout=true';
       this.navigateToSignOut(signOutURL);
    });

    // start watching for idleness if the user is authorised.
    if (this.checkIfAuthorised()) {
        this.startIdle();
    }
}
}
