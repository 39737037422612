import { Component, ViewChild, ElementRef } from '@angular/core';
declare var jQuery: any;

@Component({
  template: `<p>
  modal-base works!
</p>
`,
  styles: [``]
})
export class ModalBaseComponent {

  constructor() { }
  @ViewChild('modal') modal: ElementRef;

  show(config?: any) {
    const defaultConfig = { detachable: false };

    /* istanbul ignore next: no easy way to test JQuery */
    if (jQuery) {
        jQuery(this.modal.nativeElement).modal(config ? config : defaultConfig).modal('show').modal('refresh');
    }
  }

  hide() {
    /* istanbul ignore next: no easy way to test JQuery */
    if (jQuery) {
      jQuery(this.modal.nativeElement).modal('hide');
    }
  }


}
