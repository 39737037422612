import { Injectable } from '@angular/core';
import { KeyCloakService } from './key-cloak.service';
import { UserModel } from '../models/user-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlParserService } from './url-parser.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonUserService {

  constructor(private keycloakService: KeyCloakService, private urlParserService: UrlParserService, private httpClient: HttpClient) { }

    public getUser(): Observable<UserModel> {
        return Observable.create(observer => {
            observer.next(this.getUserFromToken());
            observer.complete();
        });
    }

    public signOut(): void {
        this.keycloakService.finalise();
    }

    public getSignOutUrl(): string {
        return this.keycloakService.keycloak.createLogoutUrl(null);
    }

    public getMyProfileUrl(): Observable<string> {
        const url = this.urlParserService.join('gaming-site-user-management') + '/myProfile';
        return this.httpClient.get(url, {responseType: 'text'}).pipe(
        map((response: any) => {
            return url;
          }));
    }

    public getToken() {
        return this.keycloakService.keycloak.token;
    }

    public getFullScopeToken() {
        return this.keycloakService.keycloak.tokenParsed;
    }

    public userHasPrivilege(privilege: string, application?: string) {
        let rslt = false;
        const user = this.getUserFromToken();
        let appsTosearch = user.clientPrivileges;

        if (application) {
            appsTosearch = [user.clientPrivileges.find(o => o.name === application)];
        }

        appsTosearch.forEach(app => {
            if (app.userPrivileges.some(o => o === privilege)) { rslt = true; }
        });

        return rslt;
    }

    private getUserFromToken(): UserModel {
        const token = this.keycloakService.keycloak.tokenParsed;

        if (!token || !token['firstName'] || !token['resource_access'] ) { throw new Error('[MAXIAM] UserInfo - InvalidToken'); }

        const rslt = new UserModel();
        rslt.userName = token['username'];
        rslt.userKey = token['userKey'];
        rslt.firstName = token['firstName'];
        rslt.lastName = token['lastName'];
        rslt.email = token['email'];
        rslt.userRoles = token['userroles'];
        rslt.clientPrivileges = [];

        const keys = Object.keys(token.resource_access);
        keys.forEach(element => {
            rslt.clientPrivileges.push({
            name: element,
            userPrivileges: token.resource_access[element].roles
          });
        });

        return rslt;
    }
}
