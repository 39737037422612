import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const ConfigLocation = new InjectionToken<string>('config');

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: any;
  public isReady = false;

  constructor(private http: HttpClient, @Inject(ConfigLocation) private configLocation: string) {
  }

  init(): Promise<void> {
    return this.http.get(this.configLocation).toPromise().then(
      data => {
        this.config = data;
        this.isReady = true;
        return;
      }
    );
  }

  get(configKey: string): any {
    if (!this.isReady) { throw new Error('ConfigService has not been initialised!'); }
    return this.getImplementation(this.config, configKey);
  }

  /**
  * Underlying get mechanism
  *
  * private
  * method getImplementation
  * param config {object} - Object to get the property for
  * param configKey {string | array[string]} - The property name to get (as an array or '.' delimited string)
  * return value {*} - Property value, including undefined if not defined.
  */
  private getImplementation(config: {}, configKey: any) {
    const t = this,
      elems = Array.isArray(configKey) ? configKey : configKey.split('.'),
      name = elems[0],
      value = config[name];
    if (elems.length <= 1) {
      return value;
    }
    // Note that typeof null === 'object'
    if (value === null || typeof value !== 'object') {
      return undefined;
    }
    return this.getImplementation(value, elems.slice(1));
  }
}
