import { DateFormatType } from '../models/enums-model';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-with-locales-es6';

@Pipe({
    // tslint:disable-next-line:pipe-naming
    name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

    private availableFormats: any = {
        [DateFormatType.Date]: {
            dropTime: true,
            format: 'Do MMM YYYY',
        },
        [DateFormatType.DateTime]: {
            dropTime: false,
            format: 'Do MMM YYYY, HH:mm:ss',
        },
        [DateFormatType.FullDate]: {
            dropTime: true,
            format: 'ddd, Do MMM YYYY',
        },
        [DateFormatType.FullDateTime]: {
            dropTime: false,
            format: 'ddd, Do MMM YYYY, HH:mm:ss',
        },
        [DateFormatType.ExportDateTime]: {
            dropTime: false,
            format: 'YYYY-MM-DD HH:mm:ss',
        },
    };
    private defaultFormatName = 'datetime';

    public transform(value: any, formatName?: string): string {

        if (!value) {
            return '';
        }

        if (formatName == null || !this.availableFormats[formatName]) {
            formatName = this.defaultFormatName;
        }

        const contextFormat = this.availableFormats[formatName];
        let date = moment.isMoment(value) ? value : moment.parseZone(value);
        if (contextFormat.dropTime) {
            date = date.startOf('day');
        }

        return date.format(contextFormat.format);
    }
}
