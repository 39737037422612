import { Component, OnInit, Input, ChangeDetectionStrategy, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng2common-alert-modal',
  template: `<div class="ui small confirmSave modal {{class}}" #modal>
  <div id="{{id}}" class="header">{{header}}</div>
  <div class="content">
      <div class="description">
          <p id="{{id}}Description">{{description}}</p>
      </div>
  </div>
  <div class="actions segments">
      <button id="{{id}}ApproveButton" class="ui basic button approve" (click)="onOK()">
          <i class="checkmark icon"></i>{{ok || 'OK'}}
      </button>
  </div>
</div>`,
  styles: [``]
})
export class AlertModalComponent extends ModalBaseComponent implements OnInit {
  @Input() header: string;
  @Input() id: string;
  @Input() class: string;
  @Input() description: string;
  @Input() ok: string;
  @Output() public okPressed: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }

  onOK() {
    this.okPressed.emit();
  }

}
