import { NgModule, ModuleWithProviders } from '@angular/core';
import { GamingComponentNgxCommonComponent } from './gaming-component-ngx-common.component';
import { AlertModalComponent } from './components/alert-modal-component/alert-modal.component';
import { BreadcrumbComponent } from './components/breadcrumb-component/breadcrumb.component';
import { BreadcrumbSectionComponent } from './components/breadcrumb-section-component/breadcrumb-section.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateTimeRawPickerComponent } from './components/date-time-raw-picker/date-time-raw-picker.component';
import { LoadAndErrorComponent } from './components/load-and-error/load-and-error.component';
import { SortControlComponent } from './components/sort-control/sort-control.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ReportDataValuePipe } from './pipes/report-data-value.pipe';
import { TableSearchComponent } from './components/table-search/table-search.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { RouterModule } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CommonUserService } from './services/common-user.service';
import { UrlParserService } from './services/url-parser.service';
import { IdleService } from './services/idle.service';
import { GamingRequestInterceptor } from './utils/gaming-request-options';
import { KeyCloakService } from './services/key-cloak.service';
import { ConfigService } from './services/config.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DataSearchingComponent } from './components/data-search/data-searching.component';
import { DataSortingComponent } from './components/data-sorting/data-sorting.component';
import { FilterControlComponent } from './components/filter-control/filter-control.component';
import { FilterControlDropDownRefDataComponent } from './components/filter-control/filter-control-drop-down-ref-data.component';
import { MultiSelectDropDownFilterComponent } from './components/filter-control/multi-select-dropdown-filter.component';
import { ModalBaseComponent } from './components/modal-base/modal-base.component';


export function jwtOptionsFactory(cs: CookieService) {
  const tokenGetter = () => {
    const value = cs.get('maxsys2accesstoken');
    return (value != null) ? value : null;
  };
  return {
    tokenGetter: tokenGetter,
    // null (local served) is required due to an issue with angular jwt - https://github.com/auth0/angular2-jwt/issues/504
    whitelistedDomains: [/.*max\-?sys.com.au/i, 'localhost', , /^null$/], //
  };
}

@NgModule({
  imports: [
    RouterModule,
    NgIdleModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CookieModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CookieService]
      }
    }),
    ToastrModule.forRoot()
  ],
  declarations: [
    GamingComponentNgxCommonComponent,
    AlertModalComponent,
    BreadcrumbComponent,
    BreadcrumbSectionComponent,
    ConfirmModalComponent,
    DatePickerComponent,
    DateTimeRawPickerComponent,
    LoadAndErrorComponent,
    SortControlComponent,
    DateFormatPipe,
    ReportDataValuePipe,
    TableSearchComponent,
    MainMenuComponent,
    SiteFooterComponent,
    SiteHeaderComponent,
    MonthPickerComponent,
    CallbackPipe,
    MobileMenuComponent,
    DataSearchingComponent,
    DataSortingComponent,
    FilterControlComponent,
    FilterControlDropDownRefDataComponent,
    MultiSelectDropDownFilterComponent,
    ModalBaseComponent
  ],
  providers: [
    CommonUserService,
    UrlParserService,
    IdleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GamingRequestInterceptor,
      multi: true
    },
    CookieService,
    KeyCloakService,
    ConfigService
  ],
  exports: [
    BreadcrumbComponent,
    BreadcrumbSectionComponent,
    DatePickerComponent,
    LoadAndErrorComponent,
    SortControlComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    MainMenuComponent,
    MobileMenuComponent,
    DateFormatPipe,
    ReportDataValuePipe,
    CallbackPipe,
    MonthPickerComponent,
    TableSearchComponent,
    DataSearchingComponent,
    DataSortingComponent,
    FilterControlComponent,
    FilterControlDropDownRefDataComponent,
    MultiSelectDropDownFilterComponent
  ]
})
export class GamingComponentNgxCommonModule {
  /**
   * Use this method in your root module to provide the CookieService
   * returns {ModuleWithProviders}
   */
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GamingComponentNgxCommonModule,
      providers: [
        CommonUserService,
        UrlParserService,
        IdleService,
        CookieService,
        KeyCloakService,
        ConfigService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: GamingRequestInterceptor,
          multi: true
        }
      ],
    };
  }

  /**
   * Use this method in your other (non root) modules to import the directive/pipe
   * returns {ModuleWithProviders}
   */
  static forChild(): ModuleWithProviders {
    return {
      ngModule: GamingComponentNgxCommonModule,
      providers: [
        CommonUserService,
        UrlParserService,
        IdleService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: GamingRequestInterceptor,
          multi: true
        },
        CookieService,
        KeyCloakService,
        ConfigService
      ],
    };
  }

}
