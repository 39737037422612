export class UserModel {
  public userKey: string;
  public userName: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public userRoles: Array<string>;
  public clientPrivileges: Array<ClientPrivileges>;
}

export class ClientPrivileges {
  public name: string;
  public userPrivileges: Array<string>;
}
