import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

export const DefaultParentDomainToken = new InjectionToken('DefaultParentDomain');

@Injectable({
  providedIn: 'root'
})
export class UrlParserService {
  private parentDomain: string;

  constructor(@Optional() @Inject(DefaultParentDomainToken) parentDomain?: string) {
    this.parentDomain = parentDomain || 'int.max-sys.com.au';
  }

  /* istanbul ignore next: built in browser logic */
  getWindowLocationHost(): string {
    return window.location.hostname;
  }

  /* istanbul ignore next: built in browser logic */
  getWindowLocationProtocol(): string {
    return window.location.protocol;
  }

  parse() {
    const parts: string[] = this.getWindowLocationHost().split('.');
    const subdomain: string = parts[0];
    if (subdomain === 'localhost') {
      return {
        protocol: this.getWindowLocationProtocol(),
        subdomain,
        domain: this.parentDomain
      };
    }
    parts.splice(0, 1); // Remove the first element (aka the subdomain)
    return {
      protocol: this.getWindowLocationProtocol(),
      subdomain,
      domain: parts.join('.')
    };
  }

  join(service: string) {
    const parsed: any = this.parse();
    return `${parsed.protocol}//${service}.${parsed.domain}`;
  }
}
