import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'ng2common-load-and-error',
  template: `<div *ngIf="isLoading" class="ui active inverted dimmer">
  <div class="ui text loader">{{ loadingMessage }}</div>
</div>
<div *ngIf="errorMessage"  class="ui fluid container">
  <div class="textCentre">
      <i id="errorIcon" class="icon" [ngClass]="icon"></i>
      <h1 id="errorHeader" class="ui header">{{errorMessage}}</h1>
      <p id="errorHelp" [innerHTML]="errorHelp"></p>
  </div>
</div>`,
  styles: [``]
})
export class LoadAndErrorComponent implements OnChanges {
  @Input() public errorState: string;
  @Input() public isLoading: Boolean;
  @Input() public loadingMessage = 'Loading';

  public errorMessage: string;
  public errorHelp: string;
  public icon: string;

  public ngOnChanges(changes: any) {
      if (this.errorState) {
          this.handleError();
      }
  }

  private handleError() {
      console.error(`error occured: ${this.errorState}`);
      this.isLoading = false;
      const errorObj = JSON.parse(this.errorState);
      const errorCode = parseInt(errorObj.status, 10);
      if (errorCode === 401 || errorCode === 403) {
          this.errorMessage = `Hmm, you don't seem to have access to view this page`;
          this.errorHelp =    `If you don't think that's right, please contact your
                              manager who can organise access for you.`;
          this.icon = 'noAccess';
      } else if (errorCode === 404) {
          this.errorMessage = `Hmm, we can't seem to find what you're looking for.`;
          this.errorHelp =    `If you don't think that's right, please let
                              Helpdesk know at 1800 307 551 or
                              <a href="mailto:dms@max.com.au?subject=MAXsys Page Not Found">dms@max.com.au</a>`;
          this.icon = 'notFound';
      } else  {
          this.errorMessage = `Oops, looks like we're having some technical issues.`;
          this.errorHelp =    `Try again in a few minutes. If this happens again, let
                              Helpdesk know at 1800 307 551  or
                              <a href="mailto:dms@max.com.au?subject=MAXsys Page Not Found">dms@max.com.au</a>`;
          this.icon = 'techSupport';
      }
  }

}
