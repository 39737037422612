import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { UrlParserService } from './url-parser.service';
import { ISelectedItem } from '../models/dynamic-menu-model';

import { BehaviorSubject ,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuItemService {
  private baseUrl = this.parser.join('gaming-service-menu');
  private _selectedItem = new BehaviorSubject<ISelectedItem>(null);
  public selectedItem$: Observable<ISelectedItem> = this._selectedItem.asObservable();

  constructor(private http: HttpClient, private parser: UrlParserService) { }

  populateUrls(items) {
    items.forEach(x => {
      x.isExpanded = false;
      if (x.resourcePath) {
        x.iconUrl = this.parser.join(x.resourcePath.serviceName) + '/' + x.resourcePath.pathSegments.join('/');
      }
      if (x.actionPath) {
        x.serviceName = x.actionPath.serviceName;
        x.linkUrl = this.parser.join(x.actionPath.serviceName) + '/' + x.actionPath.pathSegments.join('/');

        if (this.parser.parse().subdomain === x.actionPath.serviceName && x.actionPath.localRoute) {
          x.localRoute = x.actionPath.localRoute;
        }
      }
      if (x.subItems) {
        this.populateUrls(x.subItems);
      }
    });
  }

  getMenuItems() {
    return this.http.get(`${this.baseUrl}/navigation`).toPromise()
      .then((items: any) => {
        this.populateUrls(items);
        return items;
      });
  }


  public setSelectedItem(selectedItem: ISelectedItem) {
    return this._selectedItem.next(selectedItem);
  }
}

@Injectable()
export class FakeMenuItemService extends MenuItemService {
  /* istanbul ignore next: no need to test a fake service */
  dummyMenu: any = [
    {
      'menuItemKey': 'home',
      'displayText': 'Home Page',
      'itemType': 'link',
      'actionPath': {
        'serviceName': 'gaming-site-www',
        'localRoute': ['/SignIn'],
        'pathSegments': []
      },
      'resourcePath': {
        'serviceName': 'gaming-site-www',
        'pathSegments': [
          'img',
          'ico-nav-home.svg'
        ]
      },
      'description': 'Visit the MaxSys 2.0 Home Page',
    },
    {
      'menuItemKey': 'servicedesk',
      'displayText': 'Service Desk',
      'itemType': 'link',
      'actionPath': {
        'serviceName': 'gaming-site-servicedesk',
        'pathSegments': []
      },
      'resourcePath': {
        'serviceName': 'gaming-site-servicedesk',
        'pathSegments': [
          'img',
          'ico-nav-helpdesk.svg'
        ]
      },
      'description': 'Visit the service desk and log/view your requests.',
      'subItems': [
        {
          'menuItemKey': 'servicedesk.requests',
          'displayText': 'Requests',
          'itemType': 'link',
          'description': 'Manage your service desk requests',
          'subItems': [
            {
              'menuItemKey': 'create',
              'displayText': 'Create New Request',
              'itemType': 'link',
              'actionPath': {
                'serviceName': 'gaming-site-servicedesk',
                'pathSegments': [
                  'requests',
                  'create'
                ]
              },
              'description': 'Log a new service desk support ticket.'
            },
            {
              'menuItemKey': 'servicedesk.list',
              'displayText': 'Open Requests',
              'itemType': 'link',
              'actionPath': {
                'serviceName': 'gaming-site-servicedesk',
                'pathSegments': [
                  'requests',
                  'list'
                ]
              },
              'description': 'View a list of service desk requests you have raised.',
              'notificationCount': 42
            }
          ]
        },
        {
          'menuItemKey': 'options',
          'displayText': 'Preferences',
          'itemType': 'link',
          'description': 'Manage your service desk preferences',
          'subItems': [
            {
              'menuItemKey': 'servicedesk.notifications',
              'displayText': 'Notification Settings',
              'itemType': 'link',
              'actionPath': {
                'serviceName': 'gaming-site-servicedesk',
                'pathSegments': [
                  'options',
                  'notifications'
                ]
              },
              'description': 'Change how you are informed of updates to your tickets',
            }
          ]
        }
      ]
    },
    {
      'menuItemKey': 'signin',
      'displayText': 'Sign In',
      'itemType': 'link',
      'actionPath': {
        'serviceName': 'gaming-site-signin',
        'pathSegments': []
      },
      'resourcePath': {
        'serviceName': 'gaming-site-signin',
        'pathSegments': [
          'icons',
          'ico-nav-signin.svg'
        ]
      },
      'description': 'Sign In to the MaxSys system.'
    }
  ];

  /* istanbul ignore next: no need to test a fake service */
  constructor(http: HttpClient, parser: UrlParserService) {
    super(http, parser);
  }

  /* istanbul ignore next: no need to test a fake service */
  getMenuItems() {
    this.populateUrls(this.dummyMenu);
    return Promise.resolve(this.dummyMenu);
  }
}
