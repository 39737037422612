import { Component, EventEmitter, Input, Output} from '@angular/core';
import { IFilterControl } from './filter-control.component';
declare var $: any;

@Component({
    selector: 'ngxcommon-dropDownReferenceDataFilter',
     template: `
                <div class="ui">
                    <div class="ui simple dropdown selection">
                        {{filterControl.selectedData.filterDescription}}
                        <i class="dropdown icon"></i>
                        <div class="menu" [attr.id]="filterControl.name + '_filter'">
                            <div    [attr.id]="item.value" class="item"
                                    [ngClass]="{active: filterControl.selectedData.value === item.value}"
                                    *ngFor="let item of filterControl.data"
                                    (click)="FilterApply(item)">{{item.filterDescription}}
                            </div>
                        </div>
                    </div>
                </div>
                <label>{{filterControl.title}}</label>
    `,
})

export class FilterControlDropDownRefDataComponent {
    @Input() public filterControl: IFilterControl;
    @Output() public refFilterApply: EventEmitter<any> = new EventEmitter();

    public FilterApply(item) {
        this.filterControl.activate(item);
        this.refFilterApply.emit(this.filterControl);
    }
}
