import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment-with-locales-es6';

declare var $: any;

@Component({
  selector: 'ng2common-date-picker',
  template: `<div class="ui icon input">
  <i class="icon calendar"></i>
  <input type="text" [attr.id]="name" class="dateRange" 
  readonly="readonly" [attr.value]="dateValue" (onChange)="onChangeDate($event)">
</div>
<label>Date Range</label>`,
  styles: [``]
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  @Input() public name: string;
  @Input() public startDate: moment.Moment;
  @Input() public endDate: moment.Moment;
  @Input() public maxDate: moment.Moment = moment();
  @Input() public minDate: moment.Moment = moment().subtract(3, 'year');
  @Input() public onChangeDate: any;
  @Input() public showTime: boolean;
  @Input() public selectedRange: string = null;
  @Output() public datePicked: EventEmitter<any> = new EventEmitter();
  @Input() public ranges: {} = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
      'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
      'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'),
                     moment().subtract(1, 'month').endOf('month')],
      'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
   };

  private format = 'DD MMM YYYY hh:mm A';

  get dateValue(): string {
      if (this.showTime === true) {
          return ((this.startDate) && (this.endDate)) ? (`${moment(this.startDate).format('DD MMM YYYY hh:mm A')} to ${moment(this.endDate).format('DD MMM YYYY hh:mm A')}`) : '';
      } else {
          return `${this.startDate.format('D MMM YYYY')} to ${this.endDate.format('D MMM YYYY')}`;
      }
  }

  public ngAfterViewInit() {
      if (typeof $ === 'undefined') { return; }

      if (this.showTime === undefined || this.showTime === false) {
          this.showTime = false;
          this.format = 'D MMM YYYY';
      }

      this.setDatesByRange();

      /* istanbul ignore next: no easy way to test JQuery */
      $(`#${this.name}`).daterangepicker({
          alwaysShowCalendars: true,
          format: this.format,
          startDate: this.startDate,
          endDate: this.endDate,
          maxDate: this.maxDate,
          minDate: this.minDate,
          timePicker: this.showTime,
          timePickerIncrement: 15,
          ranges: this.ranges,
      }, () => this.applyDates());
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.first.left').off('mouseenter', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.second.right').off('mouseenter', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.first.left').off('mouseleave', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.calendar.second.right').off('mouseleave', '**');
       /* istanbul ignore next: no easy way to test JQuery */
      $('div.ranges ul li ').on('click', (e) => {
          this.selectedRange = e.currentTarget.firstChild.data;
      });

      $(`#${this.name}`).on('apply.daterangepicker', (ev, picker) => {
          this.applyDates();
       });

       /* istanbul ignore next: no easy way to test JQuery */
      $('input[name="daterangepicker_start"]').on('focusout', (event) => {
          let time = moment(event.target.value);
          const minDate = $('#' + this.name).data('daterangepicker').minDate;
          const maxDate = $('#' + this.name).data('daterangepicker').maxDate;

          if (!time.isValid() || minDate.isAfter(time) || maxDate.isBefore(time)) {
              time = $('#' + this.name).data('daterangepicker').oldStartDate;
          }

          $(`#${this.name}`).data('daterangepicker').setStartDate(time);
      });
      /* istanbul ignore next: no easy way to test JQuery */
      $('input[name="daterangepicker_end"]').on('focusout', (event) => {
          let time = moment(event.target.value);
          const minDate = $('#' + this.name).data('daterangepicker').minDate;
          const maxDate = $('#' + this.name).data('daterangepicker').maxDate;

          if (!time.isValid() || minDate.isAfter(time) || maxDate.isBefore(time)) {
              time = $('#' + this.name).data('daterangepicker').oldEndDate;
          }

          $(`#${this.name}`).data('daterangepicker').setEndDate(time);
      });

      this.applyDates();
  }

  public setDatesByRange() {
      const validDateRangeKeys = Object.keys(this.ranges);
      if (this.selectedRange && validDateRangeKeys.findIndex((dateRange) => dateRange === this.selectedRange) > -1 ) {
          this.startDate = this.ranges[this.selectedRange][0];
          this.endDate = this.ranges[this.selectedRange][1];
      }
  }

  public resetDateRange() {
      if ( !this.selectedRange ) { return; }
      const range = this.ranges[this.selectedRange];
      if ( !range ) {
          this.selectedRange = null;
          return;
      }
      if ( range[0].valueOf() !== this.startDate.valueOf() ) {
          this.selectedRange = null;
      }
      if ( range[1].valueOf() !== this.endDate.valueOf() &&
          this.endDate.endOf('day').valueOf() !== moment(this.maxDate).endOf('day').valueOf() ) {
              this.selectedRange = null;
      }
  }

  private applyDates() {
      const picker = $(`#${this.name}`).data('daterangepicker');
      this.startDate = picker.startDate;
      this.endDate = picker.endDate;
      this.resetDateRange();
      if (this.showTime) {
          this.datePicked.emit({endDate: moment(picker.endDate).valueOf(), startDate:  moment(picker.startDate).valueOf(), selectedRange: this.selectedRange} );
      } else {
          this.datePicked.emit({endDate: picker.endDate, startDate: picker.startDate, selectedRange: this.selectedRange});
      }
  }
  constructor() { }

  ngOnInit() {
  }

}
