export class BreadCrumbModel {
  public isLink: boolean;
  public path: Array<any>;
  public title: string;
  public child: BreadCrumbModel;

  constructor(isLink: boolean, path: Array<any>, title: string, child: BreadCrumbModel) {
      this.isLink = isLink;
      this.path = path;
      this.title = title;
      this.child = child;
  }

}
