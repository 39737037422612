import { Component, OnInit, Input } from '@angular/core';
import { BreadCrumbModel } from '../../models/breadcrumb-model';

@Component({
  selector: 'ng2common-breadcrumb-section',
  template: `<div class="ui two column grid breadcrumbNav" *ngIf="data">
  <div class="left floated column backBtn"><a [routerLink]="data.path">
      <i class="chevron left icon"></i>{{data.title}}</a>
  </div>
  <div class="right floated column right aligned breadcrumbs">               
      <ng2common-breadcrumb *ngIf="data.child" [data]="data.child"></ng2common-breadcrumb>
  </div>
</div>
`,
  styles: [``]
})
export class BreadcrumbSectionComponent implements OnInit {
  @Input() public data: BreadCrumbModel;
  constructor() { }

  ngOnInit() {
  }

}
