import { Component, OnInit, Input } from '@angular/core';
import { BreadCrumbModel } from '../../models/breadcrumb-model';

@Component({
  selector: 'ng2common-breadcrumb',
  template: `<a [attr.id]="breadcrumbId" *ngIf="data.isLink" [routerLink]="data.path">{{data.title}}</a>
<span [attr.id]="breadcrumbId" *ngIf="!data.isLink">{{data.title}}</span>
<span [attr.id]="breadcrumbSeperatorId" *ngIf="data.child" class="seperator"> / </span>
<ng2common-breadcrumb *ngIf="data.child" [data]="data.child"></ng2common-breadcrumb>`,
  styles: [``]
})
export class BreadcrumbComponent implements OnInit {
  @Input() public data: BreadCrumbModel;

  get breadcrumbId() {
      return `${this.data.title.replace(/ /g, '')}Breadcrumb`;
  }

  get breadcrumbSeperatorId() {
      return `${this.data.title.replace(/ /g, '')}BreadcrumbSeperator`;
  }
  constructor() { }

  ngOnInit() {
  }

}
