export class TableSearchModel {
  // List of columns to search on the row
  public columnNames: Array<string>;
  // Current filter string
  public searchString: string;
  // Custom breakout method if consumer wants to define their own filter logic
  // Caller is expected to provide a callback where first argument is the row, and second argument is the search string
  // It should return a boolean, true means show, false means don't show
  public customFilterMethod?: (row: any, filter: string) => boolean;
}
