import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CommonUserService } from '../../services/common-user.service';
import { UserModel } from '../../models/user-model';

declare var jQuery: any;
@Component({
  selector: 'ng2common-site-header',
  template: `<div class="ui top fixed menu main">
  <div class="brand item">
      <i class="icon logo MAXsys"></i>
  </div>
  <div class="right menu">
      <div class="ui right dropdown item" tabindex="0" #maxHeaderDropDown>
          <div id="headerInitials" class="user initials tight">{{initials}}</div>
          <i class="small chevron down icon"></i>
          <div class="menu transition hidden" tabindex="-1" style="display: block !important;">
              <div class="currentUser">
                  <span class="label">Signed in as</span>
                  <span id='headerUsersName'>{{usersName}}</span>
              </div>
              <a *ngIf="myProfileUrl" id='myProfileLink' class="item" href="{{myProfileUrl}}">My Profile</a>
              <a id='signOutHeaderLink' class="item" (click)="signOut();" href="{{getSignOutUrl()}}">Sign out</a>
          </div>
      </div>
      <div class="ui item basic icon button nav" (click)="mobileMenuVisible = !mobileMenuVisible;">
          <i class="icon" [ngClass]="{remove: mobileMenuVisible, content: !mobileMenuVisible }"></i>
      </div>
  </div>
  </div>
  <ng2common-mobile-menu [isVisible]="mobileMenuVisible"></ng2common-mobile-menu>`,
  styles: [``]
})
export class SiteHeaderComponent implements OnInit, AfterViewInit {

  public data: UserModel;
    public mobileMenuVisible = false;
    public myProfileUrl: string = null;
    @ViewChild('maxHeaderDropDown') maxHeaderDropDown: ElementRef;

    constructor(public userService: CommonUserService) { }

    get initials(): string {
        const initial1 = this.data && this.data.firstName ? this.data.firstName.substring(0, 1).toUpperCase() : '';
        const initial2 = this.data && this.data.lastName ? this.data.lastName.substring(0, 1).toUpperCase() : '';
        return `${initial1}${initial2}`;
    }

    get usersName(): string {
        const firstName = this.data && this.data.firstName ? this.data.firstName : '';
        const lastName = this.data && this.data.lastName ? this.data.lastName : '';
        return `${firstName} ${lastName}`;
    }

    public ngOnInit() {
        this.userService.getUser().subscribe(
            user => this.data = user,
            error => console.log(error));
        this.userService.getMyProfileUrl().subscribe(
            url => this.myProfileUrl = url,
            error => console.log(error));
    }

    public getSignOutUrl() {
        return this.userService.getSignOutUrl();
    }

    public signOut() {
        this.userService.signOut();
    }

    public ngAfterViewInit() {
        /* istanbul ignore next: no easy way to test JQuery */
        if (jQuery) {
            jQuery(this.maxHeaderDropDown.nativeElement).dropdown();
        }
    }

}
