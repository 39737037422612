import { interval as observableInterval, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import * as Keycloak_ from 'keycloak-js';
import { KeycloakInstance } from 'keycloak-js';
import { CookieService } from 'ngx-cookie';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigService } from './config.service';
import { UrlParserService } from './url-parser.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const Keycloak = Keycloak_;

@Injectable()
export class KeyCloakService {

  public keycloak: KeycloakInstance;
  public initOptions: Keycloak.KeycloakInitOptions;
  public tokenTimeoutHandle: any;
  public isAuthorised: EventEmitter<boolean> = new EventEmitter();
  public cookieDomain: string;

  constructor(private http: HttpClient, private cookieService: CookieService,
              private configSvc: ConfigService, public jwt: JwtHelperService, private urlParse: UrlParserService, private zone: NgZone) {
    this.cookieDomain = this.urlParse.parse().domain;
  }

  public setConfig() {
    const keycloakConfig = this.configSvc.get('keycloakService.keycloak');
    if (!keycloakConfig) {
      throw Error('[MAXIAM] keycloakService.keycloak configuration can not be found!');
    }
    // hack to fix issue with JSON from keycloak not matching KeyCloakJS
    keycloakConfig['url'] = keycloakConfig['auth-server-url'];
    keycloakConfig['clientId'] = keycloakConfig['resource'];

    this.keycloak = Keycloak(keycloakConfig);
    this.initOptions = { onLoad: 'login-required' };

    if (this.configSvc.get('keycloakService.tokenCookieDomain')) {
      this.cookieDomain = this.configSvc.get('keycloakService.tokenCookieDomain');
    }
  }

  /* istanbul ignore next: difficult to test as interval timer will continously run */
  public init() {
    return new Promise((resolve, reject) =>
      this.configSvc.init().then(() => {
        this.setConfig();
        this.zone.runOutsideAngular(() => {
          this.keycloak.init(this.initOptions)
            .success((authenticated: boolean) => {
              this.getFullScopeToken(this.keycloak.token).subscribe((fullScopeTokenResponse) => {
                this.keycloak.tokenParsed = this.jwt.decodeToken(fullScopeTokenResponse['fullScopeToken']);
                this.keycloak.sessionId = this.keycloak.tokenParsed.session_state;
                this.keycloak.authenticated = true;
                this.keycloak.subject = this.keycloak.tokenParsed.sub;
                this.keycloak.realmAccess = this.keycloak.tokenParsed.realm_access;
                this.keycloak.resourceAccess = this.keycloak.tokenParsed.resource_access;
                this.isAuthorised.emit(authenticated);

                if (!authenticated) {
                  window.location.reload();
                }

                if (this.configSvc.get('keycloakService.tokenCookieName')) {
                  this.cookieService.put(this.configSvc.get('keycloakService.tokenCookieName'),
                    this.keycloak.token, { domain: this.cookieDomain });
                }

                // refresh token
                observableInterval(this.configSvc.get('keycloakService.tokenCheckInterval')).subscribe(
                  data => this.tokenRefreshHandler()
                );

                resolve();
              });
            });
        });
      }));
  }

  public finalise() {
    // clean up the token
    if (this.configSvc.get('keycloakService.tokenCookieName')) {
      this.cookieService.remove(this.configSvc.get('keycloakService.tokenCookieName'), { domain: this.cookieDomain });
    }
  }

  /**
   * Get the URL of the realm
   * *
   * returns
   * memberof KeyCloakService
   */
  public getRealmUrl() {
    if (this.keycloak.authServerUrl.charAt(this.keycloak.authServerUrl.length - 1) === '/') {
      return this.keycloak.authServerUrl + 'realms/' + encodeURIComponent(this.keycloak.realm);
    } else {
      return this.keycloak.authServerUrl + '/realms/' + encodeURIComponent(this.keycloak.realm);
    }
  }

  /**
   * A handler for refreshing the access token for KeyCloak\RedHat SSO
   * *
   * memberof KeyCloakService
   */
  public tokenRefreshHandler() {
    if (!this.keycloak.tokenParsed || this.keycloak.isTokenExpired(this.configSvc.get('keycloakService.minValidity'))) {
      const params = 'grant_type=refresh_token&' + 'refresh_token=' + this.keycloak.refreshToken
        + '&client_id=' + encodeURIComponent(this.keycloak.clientId);
      const url = this.getRealmUrl() + '/protocol/openid-connect/token';
      const timeLocal = new Date().getTime();
      this.handleRefreshTokenCall(url, params, timeLocal);
    }
  }

  /**
   * Makes a POST HTTP request for getting the refreshed tokens from KeyCloak\RedHat SSO
   * *
   * param {string} url : The URL to make the POST request to
   * param {string} params : The params to be included in the body of the request
   * returns {Observable<any>} : An object containing an id_token, access_token and refresh_token
   * memberof KeyCloakService
   */
  public refreshToken(url: string, params: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
    const requestOptions = { headers: headers, withCredentials: true };
    const timeLocal = new Date().getTime();

    return this.http.post(url, params, requestOptions).pipe(retry(3));
  }

  /**
   * Handles the token reponse from KC\RHSSO, updating the properties of the existing object
   * *
   * param {object} slimToken : The slim token returned from the auth proxy
   * param {object} fullScopeToken : An object containing an id_token, access_token and refresh_token
   * param {number} timeLocal : The local time (epoch) the refresh was called at.
   * returns void
   * memberof KeyCloakService
   */
  public setTokenHandler(slimToken: any, fullScopeToken: any, timeLocal: number) {
    // tslint:disable-next-line:no-console
    console.info('[MAX_IAM] Token refreshed.');
    timeLocal = (timeLocal + new Date().getTime()) / 2;
    this.setToken(slimToken['access_token'], fullScopeToken['fullScopeToken'], slimToken['refresh_token'], slimToken['id_token'], timeLocal);
    return;
  }

  /**
   * Makes the HTTP call for refreshing the token and handles the response
   * *
   * param {string} url : The URL to make the POST request to
   * param {string} params : The params to be included in the body of the request
   * param {number} timeLocal : The local time (epoch) the refresh was called at
   * memberof KeyCloakService
   */

  /* istanbul ignore next: is just a wrapper for refreshToken which is tested */
  public handleRefreshTokenCall(url: string, params: string, timeLocal: number) {
    this.refreshToken(url, params).subscribe(
      slimToken => this.getFullScopeToken(slimToken['access_token']).subscribe(
        fullScopeToken => this.setTokenHandler(slimToken, fullScopeToken, timeLocal)
      ),
      err => {
        console.warn('[MAX_IAM] Unable to refresh token.');
        console.error(JSON.stringify(err));
        if (err.status === 400) {
          this.finalise();
          window.location.href = this.keycloak.createLogoutUrl(null);
        }
      }
    );
  }

  /**
   * Get the time in seconds until the token expires
   * * Code taken from KeyCloakJS and slightly modified.
   * returns {number}
   * memberof KeyCloakService
   */
  public getTokenTTL(): number {
    return (this.keycloak.tokenParsed['exp'] - (new Date().getTime() / 1000) + this.keycloak.timeSkew) * 1000;
  }

  /**
   * Updates the KeyCloakInstance with tokens, clearing them out if they no longer exist.
   * * Code taken from KeyCloakJS and slightly modified.
   * private
   * param {string} slimAccessToken
   * param {string} fullScopeAccessToken
   * param {string} refreshToken
   * param {string} idToken
   * param {number} timeLocal
   * memberof KeyCloakService
   */
  private setToken(slimAccessToken: string, fullScopeAccessToken: string, refreshToken: string, idToken: string, timeLocal: number) {
    if (this.tokenTimeoutHandle) {
      clearTimeout(this.tokenTimeoutHandle);
      this.tokenTimeoutHandle = null;
    }

    if (refreshToken) {
      this.keycloak.refreshToken = refreshToken;
      this.keycloak.refreshTokenParsed = this.jwt.decodeToken(refreshToken);
    } else {
      delete this.keycloak.refreshToken;
      delete this.keycloak.refreshTokenParsed;
    }

    if (idToken) {
      this.keycloak.idToken = idToken;
      this.keycloak.idTokenParsed = this.jwt.decodeToken(idToken);
    } else {
      delete this.keycloak.idToken;
      delete this.keycloak.idTokenParsed;
    }

    if (slimAccessToken && fullScopeAccessToken) {
      this.keycloak.token = slimAccessToken;
      this.keycloak.tokenParsed = this.jwt.decodeToken(fullScopeAccessToken);
      this.keycloak.sessionId = this.keycloak.tokenParsed.session_state;
      this.keycloak.authenticated = true;
      this.keycloak.subject = this.keycloak.tokenParsed.sub;
      this.keycloak.realmAccess = this.keycloak.tokenParsed.realm_access;
      this.keycloak.resourceAccess = this.keycloak.tokenParsed.resource_access;

      if (this.configSvc.get('keycloakService.tokenCookieName')) {
        this.cookieService.put(this.configSvc.get('keycloakService.tokenCookieName'), this.keycloak.token, { domain: this.cookieDomain });
      }
      this.keycloak.timeSkew = Math.floor(timeLocal / 1000) - this.keycloak.tokenParsed.iat;

      if (this.keycloak.timeSkew != null) {
        // tslint:disable-next-line:no-console
        console.info('[MAX_IAM] Estimated time difference between browser and server is ' + this.keycloak.timeSkew + ' seconds');

        if (this.keycloak.onTokenExpired) {
          const expiresIn = this.getTokenTTL();
          // tslint:disable-next-line:no-console
          console.info('[MAX_IAM] Token expires in ' + Math.round(expiresIn / 1000) + ' s');
          if (expiresIn <= 0) {
            this.keycloak.onTokenExpired();
          } else {
            this.zone.runOutsideAngular(() => {
              this.tokenTimeoutHandle = setTimeout(this.keycloak.onTokenExpired, expiresIn);
            });
          }
        }
      }
    } else {
      delete this.keycloak.token;
      delete this.keycloak.tokenParsed;
      delete this.keycloak.subject;
      delete this.keycloak.realmAccess;
      delete this.keycloak.resourceAccess;

      this.keycloak.authenticated = false;
    }
  }

   /**
   * Grabs a fullScopeToken from the token-service,
   * private
   * param {string} slimAccessToken
   * memberof KeyCloakService
   */
  private getFullScopeToken(slimAccessToken: string) {
    const tokenServiceUrl = this.configSvc.get('keycloakService.tokenServiceUrl') + '/fullScopeTokens/' + slimAccessToken;
    return this.http.get(tokenServiceUrl).pipe(retry(3));
  }
}
