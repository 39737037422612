import { Component, OnInit, Input } from '@angular/core';
import { MenuItemService } from '../../services/menu-item.service';
import { ISelectedItem } from '../../models/dynamic-menu-model';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ng2common-mobile-menu',
  template: `<div class="ui right sidebar vertical mobile menu overlay" [ngClass]="{visible: isVisible}">
  <div class="ui inverted accordion">
      <ng-template ngFor let-item [ngForOf]="menuItems">
          <div class="item" [ngClass]="{active: item.active}">
              <div class=" title ">
                  <a *ngIf="item.localRoute " [routerLink]="item.localRoute ">
                      <img class="ui spaced image " src="{{item.iconUrl}} " alt="{{item.displayText}}" />
                      <span>{{item.displayText}}</span>
                  </a>
                  <a *ngIf="!item.localRoute " [href]="item.linkUrl ">
                      <img class="ui spaced image " src="{{item.iconUrl}} " alt="{{item.displayText}}" />
                      <span>{{item.displayText}}</span>
                  </a>
                  <i *ngIf="item.subItems?.length" class="icon" [ngClass]="{minus: item.isExpanded, accordion: !item.isExpanded }"  (click)="item.isExpanded = !item.isExpanded;"></i>
              </div>
              <div class="content" *ngIf="item.subItems?.length" [ngClass]="{active: item.isExpanded}">
                  <ng-template ngFor let-seconditem [ngForOf]="item.subItems">
                      <span class="label ">{{seconditem.displayText}}</span>
                      <ul *ngIf="seconditem.subItems?.length">
                          <li class="item " *ngFor="let thirditem of seconditem.subItems" [ngClass]="{active: thirditem.active} ">
                              <a *ngIf="thirditem.localRoute " [routerLink]="thirditem.localRoute ">{{thirditem.displayText}}</a>
                              <a *ngIf="!thirditem.localRoute " [href]="thirditem.linkUrl ">{{thirditem.displayText}}</a>
                          </li>
                      </ul>
                  </ng-template>
              </div>
          </div>
      </ng-template>
  </div>
</div>
`,
  styles: [``]
})
export class MobileMenuComponent implements OnInit {

  @Input() public isVisible: boolean;
  menuItemKey = '';
  menuItems: any[];
  collapsed = false;
  selectedItem: Observable<ISelectedItem>;

  constructor (private menuItemService: MenuItemService, private router: Router) {}

  getMenuItems() {
    this.menuItemService.getMenuItems().then(x => {
      this.menuItems = x;
      this.setState(this.menuItemKey);
    });
  }

  match(key1: string, key2: string): number {
    if (key1 == null || key2 == null) {
      return 0;
    }
    const expandedkey1 = key1.split('.');
    const expandedkey2 = key2.split('.');

    if (expandedkey1.length > expandedkey2.length) {
      return 0;
    }

    let strength = 0;
    for (let i = 0; i < expandedkey1.length; i++) {
      if (expandedkey1[i] !== expandedkey2[i]) {
        break;
      }
      strength++;
    }
    return strength;
  }

  setState(routeKey) {
    let bestmatch = 0;
    let match = null;

    if (this.menuItems == null) {
      return;
    }
    const recurseTree = (items, parent) => {
      for (const item of items) {
        item.parent = parent;
        item.active = false;

        if (item.subItems) {
          recurseTree(item.subItems, item);
        }
        const strength = this.match(item.menuItemKey, routeKey);
        if (strength > bestmatch) {
          match = item;
          bestmatch = strength;
        }
      }
    };

    recurseTree(this.menuItems, null);

    let active = match;
    while (active) {
      active.active = true;
      active = active.parent;
    }

    this.collapsed = false;
    for (const x of this.menuItems) {
      if (x.active && x.subItems && x.subItems.length > 0) {
        this.collapsed = true;
      }
    }
    console.log(this.collapsed);
    console.log(match);
  }

  ngOnInit() {
    this.getMenuItems();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const routeURLParts = event.url.replace('/', '').split('/');
        let key = '';

        for (const config of this.router.config) {
          const configURLParts = config.path.split('/');
          if (configURLParts[0] === routeURLParts[0] && configURLParts.length === routeURLParts.length) {
            if ((<any>(config.data)).menuKey) {
              key = (<any>(config.data)).menuKey;
            }
          }
        }

        this.setState(key);
        this.menuItemKey = key;
      }
    });
    this.selectedItem = this.menuItemService.selectedItem$;
  }

}
