import { Component, ElementRef, Input, Output, EventEmitter, OnInit, HostListener } from '@angular/core';
@Component({
  selector: 'ng2common-monthpicker',
  template: `<div class="field" [ngClass]="(displayError() ? 'error' : '')">
  <div class="ui fluid special selection dropdown" [ngClass]="pickYear ? 'active visible' : '' ">
      <label class="floatLabel">Year</label>
      <div id="noSemantic"></div>
      <div class="label" (click)="showPickYear()">
          {{selectedYear?.year}}
          <i class="dropdown icon" (click)="showPickYear()"></i>
      </div>
      <div class="menu transition" [ngClass]="pickYear ? 'visible' : '' ">
          <div class="scrolling menu">
              <li class="ui item" *ngFor="let year of availableYears" (click)="changeYear(year, true)" [ngClass]="year === selectedYear ? 'selected' : ''">
                  {{year.year}}
              </li>
          </div>
      </div>
  </div>
  <div class="ui fluid special selection dropdown" [ngClass]="pickMonth ? 'active visible' : ''">
      <label class="floatLabel">Month</label>
      <div id="noSemantic"></div>
      <div class="label" (click)="selectedYear ? showPickMonth() : null">
          {{selectedDate?.toLocaleDateString("en-GB", { month: "long" })}}
          <i class="dropdown icon" (click)="selectedYear ? showPickMonth() : null"></i>        
      </div>
      <div class="menu transition" [ngClass]="pickMonth ? 'visible' : '' ">
          <div class="scrolling menu">
              <li class="ui item" *ngFor="let date of selectedYear?.months" (click)="changeMonth(date)" [ngClass]="date === selectedDate ? 'selected' : ''">
                  {{date.toLocaleDateString("en-GB", { month: "long" })}}
              </li>
          </div>
      </div>
  </div>
</div>`,
  styles: [`.field{-webkit-transform:rotateZ(0);transform:rotateZ(0);z-index:4;position:relative;display:flex;flex-flow:row wrap}.field *{flex:0 0 100%}.field>.ui.selection.dropdown{-webkit-transform:initial;transform:initial;position:static;flex:1 1 50%;display:inline-block;padding-bottom:0;padding-left:0;padding-right:0}.field>.ui.selection.dropdown .floatLabel{text-transform:initial;margin-top:-1.75em;position:absolute}.field>.ui.selection.dropdown .label{position:relative;overflow:visible;padding-bottom:.75em;padding-left:.875em;padding-right:.875em;min-height:100%}.field>.ui.selection.dropdown i{min-height:30px;min-width:30px;position:absolute;right:0;top:0;bottom:0;margin:auto 0 .5em;pointer-events:none}.field>.ui.selection.dropdown+.dropdown{border-left-style:solid;border-left-width:2px}`]
})
export class MonthPickerComponent implements OnInit {

  @Input() public defaultValue: Date;
  @Output() public selectedMonth: EventEmitter<Date> = new EventEmitter();
  @Input() public showError: boolean;

  public availableYears: any[];
  public selectedYear: any;
  public selectedDate: Date;
  public pickYear: Boolean;
  public pickMonth: Boolean;

  constructor (private element: ElementRef) {
  }

  public ngOnInit() {
    this.setAvailableDates();
    this.setDefault();
  }

  // Deselecting the dropdown
  @HostListener('document: click', ['$event.target'])
  public onClick(target: HTMLElement) {
    if (!this.element.nativeElement.contains(target)) {
      this.pickMonth = false;
      this.pickYear = false;
      this.changeMonth(this.selectedDate);
    }
  }

  private setAvailableDates() {
    const currentDate = new Date();
    const previousMonths = 240;
    let startLoop = 0;
    const years = {};

    while (startLoop <= previousMonths ) {
      const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1 - startLoop, 0);
      const year = monthEnd.getFullYear();
      if (!(year in years)) {
        years[year] = {
          year,
          months: []
        };
      }
      startLoop += 1;
      years[year].months.push(monthEnd);
    }

    this.availableYears = Object.keys(years).map((key) => {
      return years[key];
    }).reverse();

    this.setDefault();
  }

  public setDefault() {
    const defaultValue = this.defaultValue;

    if (defaultValue) {
      const isValid = this.availableYears.some((availableYear) =>
        availableYear.months.some((month) => {
          if (month.getTime() === defaultValue.getTime()) {
            this.selectedYear = availableYear;
            this.selectedDate = defaultValue;
            return true;
          }
        })
      );
      if (!isValid) {
        throw new Error('Invalid default date specified.');
      }
    }

    this.changeYear(this.selectedYear, false);
    this.changeMonth(this.selectedDate);
  }

  public displayError(): boolean {
    if (this.showError && (!this.selectedYear || !this.selectedDate) && !(this.pickMonth || this.pickYear)) {
      return true;
    } else {
      return false;
    }
  }

  public changeYear(year, uiPicked) {
    this.pickYear = false;
    this.selectedYear = year;
    if (uiPicked) {
      this.changeMonth(null);
      this.showPickMonth();
    }
  }

  public changeMonth(date) {
    this.selectedDate = date;
    this.pickMonth = false;
    this.selectedMonth.emit(this.selectedDate);
  }

  public showPickYear() {
    this.pickMonth = false;
    this.pickYear = !this.pickYear;
  }

  public showPickMonth() {
    this.pickYear = false;
    this.pickMonth = !this.pickMonth;
  }

}
