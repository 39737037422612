import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'ngxcommon-dataSearching',
  template: `<div class="ui icon input">
    <input [formControl]="dataSearchTerm" id="dataSearchTerm" name="dataSearchTerm" type="text">
    <i *ngIf="dataSearchTerm.pristine" id="searchIcon" class="search icon"></i>
    <span style="position: absolute; top: 45%; right: 10px; cursor: pointer;">
        <i *ngIf="dataSearchTerm.dirty" id="resetSearchIcon" class="red remove icon" (click)="dataSearchTerm.reset();"></i>
    </span>
</div>
<label id="searchLabel" for="dataSearchTerm">{{searchHeader}}</label>`,
})

export class DataSearchingComponent implements OnInit {
  public dataSearchTerm: FormControl = new FormControl();
  public dataSearchTermSubscription;

  @Input() public searchHeader: string;
  @Output() public searchInputChanged: EventEmitter<any> = new EventEmitter();

  public ngOnInit() {
    this.dataSearchTermSubscription = this.dataSearchTerm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(
        (entitySearchTerm) => {
          const filterBy = entitySearchTerm ? entitySearchTerm : null;
          if (!filterBy) {
            // reset the form to make it pristine again.
            this.dataSearchTerm.reset();
          }

          this.searchInputChanged.emit(filterBy);
        },
    );
  }
}
