import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { ReportDataValuePipe } from '../pipes/report-data-value.pipe';
import { NumericFormatType } from './enums-model';

export interface IColumnModel {
  dataName: string;
  sortDataName: string;
  title: string;
  isSortable: boolean;
  sortDirection: string;
  cssClass: string;
  sortDataType: string;
  isSortSelected: boolean;
  prefix: string;
  suffix: string;
  detailName: string;
  format: string;

  addCssClass(className: string): void;
  parseValue(value: string): string;
}

export class ColumnModel implements IColumnModel {
  public dataName: string;
  public sortDataName: string;
  public title: string;
  public isSortable: boolean;
  public sortDirection: string;
  public cssClass = '';
  public sortDataType: string;
  public isSortSelected = false;
  public prefix = '';
  public suffix = '';
  public format = 'string';

  constructor(data: string, sortData: string, title: string, isSortable: boolean,
              sortDirection: string, cssClass: string, sortDataType: string,
              prefix?: string, suffix?: string) {
      this.dataName = data;
      this.sortDataName = sortData;
      this.title = title;
      this.isSortable = isSortable;
      this.sortDirection = sortDirection;
      this.cssClass = cssClass;
      this.sortDataType = sortDataType;
      this.prefix = prefix ? prefix : '';
      this.suffix = suffix ? suffix : '';
  }

  public get detailName() {
      return `${this.prefix}${this.title}${this.suffix}`;
  }

  public addCssClass(className: string) {
      if (this.cssClass == null || this.cssClass.length === 0) {
          this.cssClass = className;
      } else {
          this.cssClass += ' ' + className;
      }
  }

  public parseValue(value: string): string {
      return value; // no transformation required here.
  }
}

export class DateColumnModel extends ColumnModel {

  constructor(data: string, sortData: string, title: string, dateFormat: string) {
      super(data, sortData, title, true, 'ascending', '', null, '', '');
      this.format = dateFormat;
  }

  public parseValue(value: string): string {
      return new DateFormatPipe().transform(value, this.format);
  }
}
export class TableModel {
    public columns: Array<IColumnModel>;
    public exportColumns: Array<IColumnModel>;
    public rows: Array<any>;
    public filteredRows: Array<any>;
    public useFilteredRows: boolean;
    public allRows: Array<any>;
    public searchColumns: Array<string>;
    public secondarySortColumn: string;
    public secondarySortDirection: string;
    public secondarySortType: string;
    public csvHeader: string;

    public copyAllRows() {
        if (!this.rows) {
            this.rows = new Array<any>();
        }
        this.rows = this.allRows.slice(0);

        if (this.useFilteredRows) {
            if (!this.filteredRows) {
                this.filteredRows = new Array<any>();
            }
            this.filteredRows = this.allRows.slice(0);
        }
    }

    public getData(row: any, propertyName: string): string {
        return propertyName.split('.').reduce((prev: any, curr: string) => prev[curr], row);
    }

    /**
     * remapDateColumns takes a set of columns for a model do not have explicit
     * exportColumns defined, and remaps any columns with a default 'date' sort
     * applied in order to emit an CSV/Excel friendly date time.
     *
     * param columns   Original columns
     */
    public remapDateColumns(): Array<IColumnModel> {
        return this.columns.map((model) => model.sortDataType === 'date' ?
            new DateColumnModel(model.sortDataName, model.sortDataName, model.title, 'exportdatetime')
            : model);
    }

    public toCSV(): string {
        let result = 'data:text/csv;charset=utf-8,';
        const cols = this.exportColumns ? this.exportColumns : this.remapDateColumns();

        if (this.csvHeader) {
            result += `${this.csvHeader}\n`;
        }

        cols.forEach(col => {
            if (col.detailName === 'ID') {
                throw new Error('Column header cannot be called \'ID\'. ' +
                    'https://support.microsoft.com/en-us/kb/323626');
            }
            result += `${col.detailName},`;
        });

        this.rows.forEach(row => {
            result += '\n';
            cols.forEach(col => {
                let data = this.getData(row, col.dataName);
                if (data === undefined || data === null) {
                    data = '';
                }

                data = new ReportDataValuePipe().transform(data, col);

                result += `${data.toString().replace(/,/g, '')},`;

            });
        });

        return result;

    }
}


export class NumericColumnModel extends ColumnModel {

    constructor(data: string, title: string, numericFormat: string) {
        super(data, data, title, true, 'ascending', 'right aligned', 'numeric', '', '');
        this.format = numericFormat;
    }

    public parseValue(value: any): string {
        if (!!value || value === 0) {
            if (this.format === NumericFormatType.Decimal) {
                return new DecimalPipe('en-US').transform(value, '1.0-10'); // {minInteger}.{minFraction}-{maxFraction}
            }
            if (this.format === NumericFormatType.Currency) {
                return new CurrencyPipe('en-US').transform(value, 'USD', true);
            }

        }
        return super.parseValue(value);
    }
}

