import { IFilterControl } from './filter-control.component';

export class NameValuePair {
  public value: any;
  public name: string;

  constructor(value: string, name: string) {
    this.value = value;
    this.name = name;
  }
}

export class CheckBoxNameValuePair {
  public value: any;
  public name: string;
  public selected = false;

  constructor(value: any, name: string) {
    this.value = value;
    this.name = name;
  }
}

export enum ThreeWayCheck {
  All = 0,
  On = 1,
  Off = 2
}

export class NameThreeWayCheckPair {
  public value: ThreeWayCheck;
  public description: string;

  constructor(value: ThreeWayCheck, description: string) {
      this.value = value;
      this.description = description;
  }
}


// tslint:disable-next-line:max-classes-per-file
export class DropDownFilter implements IFilterControl {
  public name: string;
  public type: string;
  public title: string;
  public sizeClass: string;
  public data: any[];
  public dataType: string;
  public selectedData: any;
  public searchQuery = '';
  public showAllMessage: string;

  constructor(name: string, title: string, type: string, data: any[], dataType, showAllMessage: string) {
    this.name = name;
    this.title = title;
    this.type = type;
    this.data = data;
//    this.selectedData;
    this.dataType = dataType;
    this.sizeClass = 'eight';
    this.showAllMessage = showAllMessage;
  }

  public activate(item: NameValuePair) {
    this.selectedData = item;
  }
  public check(item: CheckBoxNameValuePair, dataType) {
    if (!this.selectedData) { this.selectedData = []; }
    const selectedDataindex = this.selectedData.findIndex((data) => item.value === data.value);
    if (selectedDataindex > -1) {
      this.selectedData.splice(selectedDataindex, 1);
    } else {
      if (dataType) { this.selectedData.push(item); }
    }
    const index = this.data.findIndex((data) => item.value === data.value);
    this.data[index].selected = !(selectedDataindex > -1);
    dataType === 'number' ? this.sortNumber(this.selectedData) : this.sortString(this.selectedData);
  }

  private sortNumber(data) {
    return data.sort((a, b) => Number(a.value) - Number(b.value));
  }
  private sortString(data) {
    return data.sort((a, b) => a.value.localeCompare(b.value));
  }

  get selectedText() {
    if (Array.isArray(this.selectedData)) {
      return this.selectedData.length ? `${this.selectedData.length} selected` : this.showAllMessage;
    } else {
      return this.selectedData ?  this.selectedData.name : this.showAllMessage;
    }
  }

  get dropDownList() {
    if (!this.data) {
      return [];
    }

    return this.data.filter((item) => {
      if (!this.searchQuery) {
        return item.selected === false;
      }
      return item.selected === false && item.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }
}

export class ReferenceData {
  public id: number;
  public name: string;
  public filterDescription: string;
  public value: any;

  constructor(id: number, name: string, description: string, value: any) {
      this.id = id;
      this.name = name;
      this.filterDescription = description;
      this.value = value;
  }
}

export class DropDownRefDataFilter implements IFilterControl {
  public name: string;
  public type = 'DropDownReferenceDataFilter';
  public title: string;
  public sizeClass: string;
  public data: Array<ReferenceData>;
  public selectedData: ReferenceData;

  constructor(name: string, title: string, data: Array<ReferenceData>,
              filterOn: string) {
      const selectedItem = new ReferenceData(0, 'all', `Show All ${filterOn}`, null);
      data.push(selectedItem);
      this.name = name;
      this.title = title;
      this.data = data;
      this.selectedData = selectedItem;
  }

  public activate(item: ReferenceData) {
      this.selectedData = item;
  }

  get selectedText() {
      return this.selectedData.filterDescription.replace('Show ', '');
  }
}

export class DropDownThreeOptionFilter implements IFilterControl {
  public name: string;
  public type = 'DropDownThreeOptionFilter';
  public title: string;
  public sizeClass: string;
  public data: Array<NameThreeWayCheckPair>;
  public selectedData: NameThreeWayCheckPair;

  constructor(name: string, title: string, data: Array<NameThreeWayCheckPair>,
              selectedItem: NameThreeWayCheckPair) {
      this.name = name;
      this.title = title;
      this.data = data;
      this.selectedData = selectedItem;
      this.sizeClass = 'eight';
  }

  public activate(item: NameThreeWayCheckPair) {
      this.selectedData = item;
  }

  get selectedText() {
      return this.selectedData.description;
  }
}
