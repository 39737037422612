import { Component, Input } from '@angular/core';

@Component({
  selector: 'ng2common-site-footer',
  template: `<footer>
  <div class="ui fluid container">
      <div class="ui grid">
          <div id="copyrightFooter" class="seven wide column" [innerHTML]="copyright"></div>
          <div class="right aligned nine wide column">
              <a id="tandCLink" [attr.href]="termsLink" target="_blank">Terms &amp; Conditions</a>
              |
              <a id="privacyPolicyLink" [attr.href]="privacyPolicyLink" target="_blank">Privacy Policy</a>
              &nbsp; &nbsp;
              <span id="needHelpSpan" [innerHTML]="'Need help&#63; '"></span>
              <i class="phone icon"></i>
              <span id="phoneNumberFooter" class="phone" [innerHTML]="phoneNumber"></span>
          </div>
      </div>
  </div>
  </footer>`,
  styles: [``]
})
export class SiteFooterComponent {
  @Input() public jurisdiction: string;

  public termsLink = 'https://www.tabcorp.com.au/terms-of-use';
  public privacyPolicyLink = 'https://www.tabcorp.com.au/privacy-policy';

  constructor() { }

  get copyright(): string {
      const year = new Date().getFullYear();
       switch (this.jurisdiction) {
           case 'NSW':
              return `&copy; ${year} Maxgaming NSW Pty Ltd ABN 51 078 250 423`;
          case 'QLD':
              return `&copy; ${year} Maxgaming QLD Pty Ltd ABN 93 078 963 050`;
           default:
              return `&copy; ${year} Maxgaming NSW Pty Ltd ABN 51 078 250 423`;
       }
  }

  get phoneNumber(): string {
      switch (this.jurisdiction) {
          case 'NSW':
              return '1800 307 551';
          case 'QLD':
              return '1800 700 116';
          default:
              return '1800 307 551';
      }
  }

}
