import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  public subject: Subject<MessageEvent>;
  public ws: WebSocket;

  public connect(url: string): Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  private create(url: string): Subject<MessageEvent> {
    this.ws = new WebSocket(url);
    this.subject = new Subject<MessageEvent>();

    /* istanbul ignore next: can't test websocket events */
    this.ws.onmessage = this.subject.next.bind(this.subject);
    /* istanbul ignore next: can't test websocket events */
    this.ws.onerror = this.subject.error.bind(this.subject);
    /* istanbul ignore next: can't test websocket events */
    this.ws.onclose = this.subject.complete.bind(this.subject);

    return this.subject;

  }

  public send(data: any) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(data));
    }
  }

  public close() {
    this.ws.close();
    this.subject.complete();
    this.subject = null;
  }
}
