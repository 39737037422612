import { Injectable, ViewContainerRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export type NotificationMethods = 'success' | 'info' | 'warning' | 'error' | 'fixedError';

export interface INotificationOption {
    message: string;
    method: NotificationMethods;
    title?: string;
}
@Injectable({
  providedIn: 'root'
})

export class NotificationService {

    public customToastOption = {
        positionClass: 'toast-top-full-width',
        closeButton: true,
        timeOut: 6000
    };
    constructor(public toastsManager: ToastrService) {
    }
    public showMsg(option) {
        const { method, title, message } = option;
        switch (method) {
            case 'success':
                this.toastsManager.success(`${message}`, `${title}`, this.customToastOption);
                break;
            case 'info':
                this.toastsManager.info(`${message}`, `${title}`, this.customToastOption);
                break;
            case 'warning':
                this.toastsManager.warning(`${message}`, `${title}`, this.customToastOption);
                break;
            case 'error':
                this.toastsManager.error(`${message}`, `${title}`, this.customToastOption);
                break;
            case 'fixedError':
                this.toastsManager.error(`${message}`, `${title}`, {tapToDismiss: true, timeOut: 0});
                break;
            case 'duplicate':
                this.toastsManager.error(`${message}`, `${title}`, this.customToastOption);
                break;
            default:
                // disable show up
                console.log('method you call doesn\'t exist');
                console.log('No notification will show up');
        }
    }
}
