import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter} from '@angular/core';
import { ModalBaseComponent } from '../modal-base/modal-base.component';

@Component({
  selector: 'ng2common-confirm-modal',
  template: `<div class="ui small confirmSave modal {{class}}" #modal>
  <div id="confirmModalHeader" class="header">{{header}}</div>
  <div class="content">
      <div class="description">
          <p id="confirmModalDescription">{{description}}</p>
      </div>
  </div>
  <div class="actions segments">
      <button id="{{denyButtonId}}" class="ui basic button grey deny" (click)="cancelSelection()">
          <i class="remove icon"></i>{{denyButtonText}}
      </button>
      <button id="{{approveButtonId}}" class="ui basic button approve" (click)="confirmSelection()">
          <i class="checkmark icon"></i>{{approveButtonText}}
      </button>
  </div>
</div>`,
  styles: [``]
})
export class ConfirmModalComponent extends ModalBaseComponent implements OnInit {
  @Input() header: string;
  @Input() class: string;
  @Input() description: string;

  @Input() denyButtonId = 'confirmModalDenyButton';
  @Input() denyButtonText = 'No thanks';
  @Input() approveButtonId = 'confirmModalApproveButton';
  @Input() approveButtonText = 'Yes please';

  @Output() public selectionConfirmed: EventEmitter<any> = new EventEmitter();
  @Output() public selectionCancelled: EventEmitter<any> = new EventEmitter();
  @Output() public confirmationResolved: EventEmitter<any> = new EventEmitter();



  confirmSelection() {
    this.confirmationResolved.emit(true);
    this.selectionConfirmed.emit();
  }

  cancelSelection() {
    this.confirmationResolved.emit(false);
    this.selectionCancelled.emit();
  }

  ngOnInit() {
  }

}
