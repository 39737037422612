import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callback',
  pure: false,
})
export class CallbackPipe implements PipeTransform {
    public transform(items: any[], callback: (item: any, args: any[]) => boolean, arg1?: any, arg2?: any, arg3?: any): any {
        if (!items || !callback) { return items; }
        return items.filter(item => callback(item, [arg1, arg2, arg3]));
    }
}
